import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'
import Test from '../views/Test.vue'
import Invite from '../views/Invite.vue'
import Sites from '../views/Sites.vue'
import Site from '../views/Site.vue'
import Service from '../views/Service.vue'
import Route from '../views/Route.vue'
import Services from '../views/Services.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/invite/:inviteId',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/sites',
    name: 'Sites',
    component: Sites
  },
  {
    path: '/sites/:id',
    name: 'Site',
    component: Site
  },
  {
    path: '/service/:id',
    name: 'Service',
    component: Service
  },
  {
    path: '/route/:id',
    name: 'Route',
    component: Route
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
