<template>
  <div class="invite">
    <div v-if="isInviteValid" class="content-container">
      <h2>Welcome {{invite.firstName}}!</h2>
      <h3>Please enter a username and password for your account</h3>
      <b-card>
        <b-form @submit.prevent="newUser">
          <b-form-group
            id="input-group-1"
            label="Username"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="username"
              placeholder="Username"
              autocapitalize="off"
              required
            ></b-form-input>
          </b-form-group>
            <label>Password</label>
            <b-form-input type="password" v-model="password" :state="validation" id="feedback-user"></b-form-input>
            <b-form-invalid-feedback :state="validation">
              <div class="validation-text">
                <p>Your password must have an uppercase letter, a number, and be at least 8 characters long.</p>
              </div>
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation">
              Password is valid
            </b-form-valid-feedback>

          <b-button type="submit" variant="info" class="submit-button">Submit</b-button>
        </b-form>
        <div class="filler"></div>
      </b-card>
    </div>
    <div v-else>
      <h2>Bad invite</h2>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Invite',
  data() {
    return {
      invite: null,
      password: '',
      username: '',
      userId: '',
      error: '',
    }
  },
  methods: {
    async newUser() {
      this.error = '';
      if (!this.validation) {
        this.error = "password validation failed";
        return;
      }

      try {
        let response = await axios.post('/api/users/registration/' + this.$route.params.inviteId, {
          firstName: this.invite.firstName,
          lastName: this.invite.lastName,
          email: this.invite.email,
          role: this.invite.role,
          username: this.username,
          password: this.password,
        });

        if (response.status == 200) {
          this.invite = null;
          this.$root.$data.user = response.data;
          this.$router.push({ path: "/"});
        }
      } catch (error) {
        this.error = error.response.data.message;
      }

    }
  },
  async created() {
    try {
      let inviteId = this.$route.params.inviteId;
      let response = await axios.get("/api/users/invite/" + inviteId);
      if (!response.data) {
        this.$router.push({ path: "/"});
        return false;
      }
      this.invite = response.data;
      return true;
    } catch(error) {
      console.log(error.message);
      this.$router.push({ path: "/"});
    }
  },
  computed: {
    isInviteValid() {
      if (this.invite != null) {
        return true;
      }
      return false;
    },
    passwordHasCapital() {
      if (this.password.search(/[A-Z]/) < 0) {
        return false;
      }
      return true;
    },
    passwordHasNumber() {
      if (this.password.search(/[0-9]/) < 0) {
        return false;
      }
      return true;
    },
    validation() {
      return this.password.length > 8 && this.passwordHasCapital && this.passwordHasNumber;
    }
  }
}
</script>

<style scoped>
.content-container {
  padding-top: 20px;
}

input {
  margin-right: 10px;
}

.error {
  margin-top: 10px;
  display: inline;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #d9534f;
  color: #fff;
}

.invalid-feedback {
  position: static;
}

.valid-feedback {
  position: static;
}

.validation-text {
  display: flex;
  justify-content: center;
}

.submit-button {
  margin-top: 10px;
}

.filler {
  flex: 1;
}
</style>
