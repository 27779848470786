<template>
  <div class="service" v-if="service">
    <b-card no-body>
      <b-card-body class="main-body">
        <b-card-title :title="service.descriptionOfWork"></b-card-title>

        <router-link :to="{ name: 'Site', params: { id: service.workSite._id }}">
          <b-card-sub-title :sub-title="service.workSite.propName"></b-card-sub-title>
        </router-link>

        <b-card-text class="IVR-link"><b-link :href="service.IVRLink">IVR Link</b-link></b-card-text>

        <div class="link-and-button" v-if="isCompleted || isReturnedResult">
          Report Returned Test Result:
          <b-button variant="info" v-b-modal.returned-result-modal class="report-button"><b-icon-exclamation-triangle></b-icon-exclamation-triangle></b-button>
        </div>
        <div class="link-and-button" v-else>
          Complete Work Order:
          <b-button variant="info" v-b-modal.completion-modal class="report-button"><b-icon-clipboard-check></b-icon-clipboard-check></b-button>
        </div>

      </b-card-body>

      <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="info" @click="toggleEditButton">Work Order Info</b-button>
        <b-button v-if="showEditButton" variant="info" @click="revealEditFields"><b-icon-pencil-square></b-icon-pencil-square></b-button>
        <b-button v-if="showEditFields" variant="info" @click="saveService"><b-icon-check-square></b-icon-check-square></b-button>
        <b-button v-if="showEditFields" variant="secondary" @click="removeEditFields"><b-icon-x-circle></b-icon-x-circle></b-button>
      </b-card-header>

      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-list-group flush v-if="!showEditFields">
            <b-list-group-item v-if="isCompleted || isReturnedResult">Status: {{service.status}} - {{formattedServiceDate()}}</b-list-group-item>
            <b-list-group-item v-else>Status: {{service.status}}</b-list-group-item>
            <b-list-group-item v-if="isReturnedResult" variant="danger">Bacteria Test Results: {{service.bacteriaTest}}</b-list-group-item>
            <b-list-group-item>Priority: {{service.priority}}</b-list-group-item>
            <b-list-group-item>Work Order Number: {{service.workOrderNumber}}</b-list-group-item>
            <b-list-group-item>Account Number: {{service.accountNumber}}</b-list-group-item>
            <b-list-group-item>Requested Date: {{service.requestedDateString}}</b-list-group-item>
            <b-list-group-item>Required Completion Date: {{service.requiredCompletionDateString}}</b-list-group-item>
            <b-list-group-item>Work Site Location: <b-link :href="siteLink">{{service.workSite.streetAddress}}, {{service.workSite.city}}</b-link></b-list-group-item>
            <b-list-group-item>Drop Location: <b-link :href="dropLink">{{service.workSite.route.dropLocation}}</b-link></b-list-group-item>
            <b-list-group-item>Drop Location Closing Time: {{service.workSite.route.timeDropLocationCloses}}</b-list-group-item>
            <b-list-group-item v-if="isUrgent || isPending || isApproaching">Technician Assigned: {{service.technicianResponsible.firstName}}</b-list-group-item>
            <b-list-group-item v-else>Completed By: {{service.technicianResponsible.firstName}}</b-list-group-item>
            <b-list-group-item v-if="service.notes">Notes: {{service.notes}}</b-list-group-item>
            <b-list-group-item v-else>Notes: None</b-list-group-item>
          </b-list-group>
          <b-list-group flush v-else>
            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Description of Work:</div>
                <b-form-textarea v-model="editDescriptionOfWork" rows="1" max-rows="4"></b-form-textarea>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">IVR Link:</div>
                <b-form-input v-model="editIVRLink"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Status:</div>
                <b-form-select v-model="editStatus" :options="statusOptions"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item v-if="isCompleted || isReturnedResult || editStatus == 'Completed' || editStatus == 'Returned Result'">
              <div class="edit-row-container">
                <div class="input-title">Service Date:</div>
                <b-form-datepicker v-model="editServiceDate" class="mb-2" value-as-date></b-form-datepicker>
              </div>
            </b-list-group-item>

            <b-list-group-item v-if="isReturnedResult || editStatus == 'Returned Result'">
              <div class="edit-row-container">
                <div class="input-title">Bacteria Test Results:</div>
                <b-form-select v-model="editBacteriaTest" :options="testResultOptions"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Priority:</div>
                <b-form-select v-model="editPriority" :options="priorities"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Work Order Number:</div>
                <b-form-input v-model="editWorkOrderNumber"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Account Number:</div>
                <b-form-input v-model="editAccountNumber"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Requested Date:</div>
                <b-form-datepicker v-model="editRequestedDate" class="mb-2" value-as-date></b-form-datepicker>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Required Completion Date:</div>
                <b-form-datepicker v-model="editRequiredCompletionDate" class="mb-2" value-as-date></b-form-datepicker>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title" v-if="isCompleted || isReturnedResult || editStatus == 'Completed' || editStatus == 'Returned Result'">Completed By:</div>
                <div class="input-title" v-else>Technician Responsible:</div>
                <b-form-select v-model="editTechnicianResponsible" :options="technicians"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Notes:</div>
                <b-form-textarea v-model="editNotes" rows="4" max-rows="10"></b-form-textarea>
              </div>
            </b-list-group-item>

            <b-list-group-item v-if="userIsAdmin">
              <b-button variant="danger" v-b-modal.delete-modal>Delete</b-button>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card v-if="isPending || isApproaching || isUrgent" no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info" @click="removeEditButton">Last Work Order</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p v-if="!lastService">No Work Orders have been completed at {{service.workSite.propName}} yet</p>
          <router-link v-else-if="lastService.status == 'Completed'" :to="{ name: 'Service', params: { id: service._id }}">
            <b-card class="pending" bg-variant="primary" text-variant="white" style="max-width: 120rem;">
              <b-card-text>{{lastService.workSite.propName}}</b-card-text>
              <b-card-text>{{lastService.descriptionOfWork}}</b-card-text>
              <b-card-text>Completed: {{serviceDateToString(lastService.serviceDate)}} by {{lastService.technicianResponsible.firstName}}</b-card-text>
            </b-card>
          </router-link>
          <router-link v-else :to="{ name: 'Service', params: { id: service._id }}">
            <b-card class="pending" bg-variant="danger" text-variant="white" style="max-width: 120rem;">
              <b-card-text>{{lastService.workSite.propName}}</b-card-text>
              <b-card-text>{{lastService.descriptionOfWork}}</b-card-text>
              <b-card-text>Bacteria Test: {{lastService.bacteriaTest}}</b-card-text>
              <b-card-text>Completed: {{serviceDateToString(lastService.serviceDate)}} by {{lastService.technicianResponsible.firstName}}</b-card-text>
            </b-card>
          </router-link>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card v-if="isPending || isApproaching || isUrgent" no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info" @click="removeEditButton">Work Orders in Same Route</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <router-link v-for="service in routeServices" :key="service._id" :to="{ name: 'Service', params: { id: service._id }}">
            <b-card v-if="service.status == 'Urgent'" bg-variant="danger" text-variant="light" style="max-width: 120rem; margin-bottom:5px;">
              <b-card-text>{{service.workSite.propName}}</b-card-text>
              <b-card-text>{{service.descriptionOfWork}}</b-card-text>
              <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
            </b-card>
            <b-card v-else-if="service.status == 'Approaching'" bg-variant="warning" text-variant="dark" style="max-width: 120rem; margin-bottom:5px;">
              <b-card-text>{{service.workSite.propName}}</b-card-text>
              <b-card-text>{{service.descriptionOfWork}}</b-card-text>
              <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
            </b-card>
            <b-card v-else class="pending" bg-variant="primary" text-variant="light" style="max-width: 120rem;">
              <b-card-text>{{service.workSite.propName}}</b-card-text>
              <b-card-text>{{service.descriptionOfWork}}</b-card-text>
              <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
            </b-card>
          </router-link>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
    </b-card>

    <b-modal id="completion-modal" title="Mark Work Order as Complete" @ok="markAsCompleted" ok-title="Complete" ok-variant="primary">
      <div>
        <div class="label-container">
          <label for="service-date">Completed Date</label>
          <b-form-datepicker id="service-date" v-model="serviceDate" class="mb-2" value-as-date></b-form-datepicker>
        </div>

        <div class="label-container">
          <label for="notes-textarea">Notes</label>
          <b-form-textarea
            id="notes-textarea"
            v-model="service.notes"
            placeholder="Enter notes from the job"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
      <p v-if="error" class="error">{{error}}</p>
    </b-modal>

    <b-modal id="returned-result-modal" title="Enter Bacteria Test Results" @ok="markAsReturnedResult" ok-title="Submit" ok-variant="primary">
      <div>
        <div class="label-container">
          <label for="bacteria-test-selector">Bacteria Test</label>
          <b-form-select id="bacteria-test-selector" v-model="bacteriaTestResults" :options="testResultOptions"></b-form-select>
        </div>

        <div class="label-container">
          <label for="notes-textarea">Notes</label>
          <b-form-textarea
            id="notes-textarea"
            v-model="service.notes"
            placeholder="Enter notes from the job"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
      <p v-if="error" class="error">{{error}}</p>
    </b-modal>

    <b-modal id="delete-modal" title="Are You Sure?" ok-title="Delete" @ok="deleteService" ok-variant="danger">
      Are you sure you want to delete this work order? This cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
  name: "Service",
  data() {
    return {
      service: null,
      lastService: null,
      routeServices: [],
      showEditButton: true,
      showEditFields: false,
      serviceDate: new Date().toISOString().slice(0,10),
      bacteriaTestResults: null,
      testResultOptions: [{ text: 'Select One', value: null }, "TC+", "TC-", "TC+/EC-", "TC+/EC+", { text: "Other, Specify in Notes", value: "See notes for details"}],
      editDescriptionOfWork: "",
      editIVRLink: "",
      editStatus: "",
      editBacteriaTest: "",
      editPriority: "",
      editWorkOrderNumber: "",
      editAccountNumber: "",
      editRequestedDate: "",
      editRequiredCompletionDate: "",
      editServiceDate: null,
      editTechnicianResponsible: null,
      editNotes: "",
      priorities: [{ text: 'Select One', value: '' }, "P1", "P2", "P3", "PM"],
      statusOptions: [{text: 'Select One', value: ''}, "Pending", "Completed", "Returned Result"],
      technicians: [{ text: 'Select One', value: null }],
      error: "",
    }
  },
  async created() {
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
      this.$router.push({ path: "/"});
    }

    try {
      let response = await axios.get('/api/services/' + this.$route.params.id);
      this.service = response.data;

      response = await axios.get('/api/users/technicians');
      for(let i = 0; i < response.data.length; i++) {
        this.technicians.push({text: response.data[i].firstName, value: response.data[i]});
      }

      if (this.service.status == "Pending" || this.service.status == "Urgent" || this.service.status == "Approaching") {
        response = await axios.get('/api/services/lastCompleted/' + this.service.workSite._id);
        this.lastService = response.data;

        response = await axios.get('/api/services/route/' + this.service.workSite.route._id + '/site/' + this.service.workSite._id);
        this.routeServices = response.data;
      }

    } catch(error) {
      this.error = error.response.data.message;
    }
  },
  computed: {
    isUrgent() {
      if (this.service.status == "Urgent") {
        return true;
      }
      return false;
    },
    isApproaching() {
      if (this.service.status == "Approaching") {
        return true;
      }
      return false;
    },
    isPending() {
      if (this.service.status == "Pending") {
        return true;
      }
      return false;
    },
    isCompleted() {
      if (this.service.status == "Completed") {
        return true;
      }
      return false;
    },
    isReturnedResult() {
      if (this.service.status == "Returned Result") {
        return true;
      }
      return false;
    },
    dropLink() {
      //create google maps link
      //maps.google.com/?q=1200%20Pennsylvania%20Ave%20SE,%20Washington,%20District%20of%20Columbia,%2020003
      let address = this.service.workSite.route.dropLocation
      let replaced = address.split(' ').join('+');
      return "https://maps.google.com/?q=" + replaced;
    },
    siteLink() {
      //create google maps link
      //maps.google.com/?q=1200%20Pennsylvania%20Ave%20SE,%20Washington,%20District%20of%20Columbia,%2020003
      let address = this.service.workSite.streetAddress + ", " + this.service.workSite.city
      let replaced = address.split(' ').join('+');
      return "https://maps.google.com/?q=" + replaced;
    },
    userIsAdmin() {
      if (this.$root.$data.user.role == "Admin") {
        return true;
      }
      return false;
    }
  },
  methods: {
    formattedServiceDate() {
      return moment(this.service.serviceDate).format("M/D/YY");
    },
    async markAsCompleted(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.error = "";
      try {
        let response = await axios.put("/api/services/completed/" + this.service._id, {
          status: "Completed",
          serviceDate: this.serviceDate,
          notes: this.service.notes,
        });

        if (response.status == 200) {
          this.service = response.data;
          this.serviceDate = new Date().toISOString().slice(0,10);
          this.$bvModal.hide('completion-modal')
        }
      } catch(error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
    async markAsReturnedResult(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.error = "";
      try {
        let response = await axios.put("/api/services/returnedResult/" + this.service._id, {
          status: "Returned Result",
          bacteriaTest: this.bacteriaTestResults,
          notes: this.service.notes,
        });

        //if successful create a new work order
        if (response.status == 200) {
          let dateToday = new Date().toISOString().slice(0,10)
          response = await axios.post("/api/services/", {
            workOrderNumber: 123456,
            priority: "P1",
            requestedDate: dateToday,
            requiredCompletionDate: dateToday,
            workSite: this.service.workSite,
            technicianResponsible: this.service.technicianResponsible,
            IVRLink: "Enter link when new work order is received",
            accountNumber: this.service.accountNumber,
            descriptionOfWork: "Respond to failed test"
          });
          if (response.status == 200) {
            this.$bvModal.hide('returned-result-modal')
          }
        }
      } catch(error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
    populateEditFields() {
      this.editDescriptionOfWork = this.service.descriptionOfWork;
      this.editIVRLink = this.service.IVRLink;

      if (this.service.status == "Urgent" || this.service.status == "Approaching") {
        this.editStatus = "Pending";
      } else {
        this.editStatus = this.service.status;
      }

      this.editBacteriaTest = this.service.bacteriaTest;
      this.editPriority = this.service.priority;
      this.editWorkOrderNumber = this.service.workOrderNumber;
      this.editAccountNumber = this.service.accountNumber;
      this.editRequestedDate = this.service.requestedDate;
      this.editRequiredCompletionDate = this.service.requiredCompletionDate;
      this.editServiceDate = this.service.serviceDate;
      this.editTechnicianResponsible = this.service.technicianResponsible;
      this.editNotes = this.service.notes;
    },
    revealEditFields() {
      this.populateEditFields();
      this.showEditButton = false;
      this.showEditFields = true;
    },
    removeEditFields() {
      this.showEditFields = false;
      this.showEditButton = true;
    },
    async saveService() {
      try {
        let response = await axios.put("/api/services/" + this.service._id, {
          descriptionOfWork: this.editDescriptionOfWork,
          IVRLink: this.editIVRLink,
          status: this.editStatus,
          bacteriaTest: this.editBacteriaTest,
          priority: this.editPriority,
          workOrderNumber: this.editWorkOrderNumber,
          accountNumber: this.editAccountNumber,
          requestedDate: this.editRequestedDate,
          requiredCompletionDate: this.editRequiredCompletionDate,
          serviceDate: this.editServiceDate,
          technicianResponsibleId: this.editTechnicianResponsible._id,
          notes: this.editNotes
        });

        if (response.status == 200) {
          this.service = response.data;
          this.showEditFields = false;
          this.showEditButton = true;
        }
      } catch(error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
    async deleteService() {
      try {
        let response = await axios.delete("/api/services/" + this.service._id);
        if (response.status == 200) {
          this.$router.push({ path: "/"});
        }
      } catch(error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
    removeEditButton() {
      this.showEditButton = false;
    },
    toggleEditButton() {
      if (this.showEditButton == false) {
        this.showEditButton = true;
      } else {
        this.showEditButton = false;
      }
    },
    serviceDateToString(serviceDate) {
      return moment(serviceDate).format("M/D/YY");
    },
  },
}
</script>

<style scoped>
.main-body {
  padding-bottom: 12px;
}

.p-1 {
  display: flex;
}

.IVR-link {
  margin-bottom: 0px;
  margin-top: 8px;
}

.collapse {
  padding-right: 0px;
  display: flex;
  justify-content: center;
}

.edit-row-container {
  display: flex;
  justify-content: flex-start;
}

.input-title {
  min-width: 115px;
}

.link-and-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-button {
  margin-left: 10px;
}

.spacer {
  visibility: hidden;
}

.label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#notes-textarea {
  margin-left: 5px;
}

.btn-info {
  background-color: #00b8e6 !important;
}

</style>
