<template>
  <div class="home">
    <HomePage v-if="user && user.role == 'Admin'" />
    <TechnicianView v-else-if="user" />
    <Login v-else/>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import HomePage from '@/components/HomePage.vue'
import TechnicianView from '@/components/TechnicianView.vue'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Login,
    HomePage,
    TechnicianView
  },
  async created() {
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
    }
  },
  computed: {
    user() {
      return this.$root.$data.user;
    }
  },
}
</script>
