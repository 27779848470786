<template>
  <div class="site" v-if="site">
    <b-card :title="site.propName" class="container">
      <b-card-text class="link-text">
        <b-link class="address-link" :href="googleLink">Address: {{site.streetAddress}}, {{site.city}}</b-link>
      </b-card-text>

      <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header v-if="infoActive && showEditFields" header-tag="header" class="p-1 tab-responsive" role="tab">
        <b-button v-b-toggle.accordion-1 class="site-info-button-small" variant="info" @click="infoNotActive">Site Info</b-button>
        <b-button variant="info" @click="saveSite"><b-icon-check-square></b-icon-check-square></b-button>
        <b-button variant="secondary" @click="removeEditFields"><b-icon-x-circle></b-icon-x-circle></b-button>
      </b-card-header>

      <b-card-header v-else-if="infoActive && !showEditFields" header-tag="header" class="p-1 tab-responsive" role="tab">
        <b-button v-b-toggle.accordion-1 class="header-button-large" variant="info" @click="infoNotActive">Site Info</b-button>
        <b-button variant="info" @click="revealEditFields"><b-icon-pencil-square></b-icon-pencil-square></b-button>
      </b-card-header>

      <b-card-header v-else header-tag="header" class="p-1 tab-responsive" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="info" @click="infoNowActive">Site Info</b-button>
      </b-card-header>

      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-list-group flush v-if="!showEditFields" style="display: flex;">
            <b-list-group-item>Property Number: {{site.propNumber}}</b-list-group-item>
            <b-list-group-item>PWS Number: {{site.pwsNum}}</b-list-group-item>
            <b-list-group-item>Source: {{site.source}}</b-list-group-item>
            <b-list-group-item>Property Type: {{site.propType}}</b-list-group-item>
            <b-list-group-item>Operating Period: {{site.operatingPeriod}}</b-list-group-item>
            <b-list-group-item>TC Frequency: {{site.tcFrequency}}</b-list-group-item>
            <b-list-group-item>Nitrate Frequency: {{site.nitrateFrequency}}</b-list-group-item>
            <b-list-group-item>Treatment Process: {{site.treatmentProcess}}</b-list-group-item>
            <b-list-group-item>FM Manager Name/Phone Number: <br>{{site.fmManagerName}} {{phoneNumber}}</b-list-group-item>
            <b-list-group-item>Chlorine Jugs: {{site.chlorineJugs}}</b-list-group-item>
            <b-list-group-item>CL17 Reagents: {{site.cl17Reagents}}</b-list-group-item>
            <b-list-group-item v-if="keyBox">Keybox Code(s): {{site.keyBoxCodes}}</b-list-group-item>
            <b-list-group-item v-if="site.route.name">Route: #{{site.route.number}} - {{site.route.name}}</b-list-group-item>
            <b-list-group-item v-else>Route: #{{site.route.number}}</b-list-group-item>
            <b-list-group-item>Technician Responsible: {{site.technicianResponsible.firstName}}</b-list-group-item>
            <b-list-group-item v-if="site.notes">Additional Notes: {{site.notes}}</b-list-group-item>
            <b-list-group-item v-else>Additional Notes: None</b-list-group-item>
          </b-list-group>

          <b-list-group flush v-else>
            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Site Name:</div>
                <b-form-textarea v-model="editPropName" rows="1" max-rows="2"></b-form-textarea>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Property Number:</div>
                <b-form-input v-model="editPropNumber"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">PWS Number:</div>
                <b-form-input v-model="editPwsNum"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Street Address:</div>
                <b-form-input v-model="editStreetAddress"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">City:</div>
                <b-form-input v-model="editCity"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Source:</div>
                <b-form-select v-model="editSource" :options="waterSources"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Property Type:</div>
                <b-form-select v-model="editPropType" :options="propTypes"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Operating Period:</div>
                <b-form-select v-model="editOperatingPeriod" :options="operatingPeriods"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">TC Frequency:</div>
                <b-form-select v-model="editTcFrequency" :options="tcFrequencies"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Nitrate Frequency:</div>
                <b-form-select v-model="editNitrateFrequency" :options="nitrateFrequencies"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Treatment Process:</div>
                <b-form-input v-model="editTreatmentProcess"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">FM Manager Name:</div>
                <b-form-input v-model="editFmManagerName"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Phone Number:</div>
                <b-form-input v-model="editFmManagerPhone"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Chlorine Jugs:</div>
                <b-form-input v-model="editChlorineJugs"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">CL17 Reagents:</div>
                <b-form-input v-model="editCl17Reagents"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Keybox Codes:</div>
                <b-form-input v-model="editExtKeyBoxCodes"></b-form-input>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Route:</div>
                <b-form-select v-model="editRoute" :options="routes"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Technician Responsible:</div>
                <b-form-select v-model="editTechnicianResponsible" :options="technicians"></b-form-select>
              </div>
            </b-list-group-item>

            <b-list-group-item>
              <div class="edit-row-container">
                <div class="input-title">Additional Notes:</div>
                <b-form-textarea v-model="editNotes" rows="4" max-rows="10"></b-form-textarea>
              </div>
            </b-list-group-item>

            <b-list-group-item v-if="userIsAdmin">
              <b-button variant="danger" v-b-modal.delete-modal>Delete</b-button>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info" @click="workOrdersNowActive">Work Orders</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card no-body>
            <b-tabs pills card align="center" active-nav-item-class="bg-info">
              <b-tab class="tab-content" title="Urgent/Pending" active>
                <div v-for="service in pendingServices" :key="service._id" >
                  <router-link v-if="service.status == 'Urgent'" :to="{ name: 'Service', params: { id: service._id }}">
                    <b-card class="pending" bg-variant="danger" text-variant="white" style="max-width: 120rem;">
                      <b-card-text>{{service.priority}} - {{service.descriptionOfWork}}</b-card-text>
                      <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
                    </b-card>
                  </router-link>
                  <router-link v-else-if="service.status == 'Approaching'" :to="{ name: 'Service', params: { id: service._id }}">
                    <b-card class="pending" bg-variant="warning" text-variant="dark" style="max-width: 120rem;">
                      <b-card-text>{{service.priority}} - {{service.descriptionOfWork}}</b-card-text>
                      <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
                    </b-card>
                  </router-link>
                  <router-link v-else :to="{ name: 'Service', params: { id: service._id }}">
                    <b-card class="pending" bg-variant="primary" text-variant="white" style="max-width: 120rem;">
                      <b-card-text>{{service.priority}} - {{service.descriptionOfWork}}</b-card-text>
                      <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
                    </b-card>
                  </router-link>
                </div>
              </b-tab>
              <b-tab class="tab-content" title="Completed">
                <div v-for="service in completedServices" :key="service._id">
                  <router-link v-if="service.status == 'Completed'" :to="{ name: 'Service', params: { id: service._id }}">
                    <b-card class="pending" bg-variant="primary" text-variant="white" style="max-width: 120rem;">
                      <b-card-text>{{service.workSite.propName}}</b-card-text>
                      <b-card-text>{{service.descriptionOfWork}}</b-card-text>
                      <b-card-text>Completed: {{serviceDateToString(service.serviceDate)}} by {{service.technicianResponsible.firstName}}</b-card-text>
                    </b-card>
                  </router-link>
                  <router-link v-else :to="{ name: 'Service', params: { id: service._id }}">
                    <b-card class="pending" bg-variant="danger" text-variant="white" style="max-width: 120rem;">
                      <b-card-text>{{service.workSite.propName}}</b-card-text>
                      <b-card-text>{{service.descriptionOfWork}}</b-card-text>
                      <b-card-text>Bacteria Test: {{service.bacteriaTest}}</b-card-text>
                      <b-card-text>Completed: {{serviceDateToString(service.serviceDate)}} by {{service.technicianResponsible.firstName}}</b-card-text>
                    </b-card>
                  </router-link>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header v-if="photosActive" header-tag="header" class="p-1 tab-responsive" role="tab">
        <b-button v-b-toggle.accordion-3 class="header-button-large" variant="info" @click="photosNotActive">Photos</b-button>
        <b-button variant="info" v-b-modal.add-photo-modal><b-icon-camera></b-icon-camera></b-button>
      </b-card-header>
      <b-card-header v-else header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info" @click="photosNowActive">Photos</b-button>
      </b-card-header>

      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-group>
            <b-card v-for="photo in photos" :key="photo._id" :img-src="photo.path" img-alt="Card image" img-top>
              <div class="photo-card">
                <b-button class="spacer" variant="danger" v-b-modal.remove-photo-modal @click="removePhotoButton(photo)"><b-icon-journal-minus></b-icon-journal-minus></b-button>
                <b-card-text>{{photo.description}}</b-card-text>
                <b-button class="remove-photo-button" variant="danger" v-b-modal.remove-photo-modal @click="removePhotoButton(photo)"><b-icon-journal-minus></b-icon-journal-minus></b-button>
              </div>
            </b-card>
          </b-card-group>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
    </b-card>

    <b-modal id="add-photo-modal" title="Upload a Photo" @ok="upload" ok-title="Upload" ok-variant="primary" @cancel="clearFields" @close="clearFields">
      <div class="imageInput">
        <div v-if="url">
          <b-img v-if="url" id="file-image" :src="url" fluid alt="Uploaded Image"></b-img>
          <b-form-textarea
            id="textarea"
            v-model="photoDescription"
            placeholder="Describe this photo..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div v-if="!url" class="placeholder">
          Choose an Image
        </div>
        <input class="fileInput" ref="fileInput" type="file" @input="fileChanged">
      </div>
      <p v-if="error" class="error">{{error}}</p>
    </b-modal>

    <b-modal id="delete-modal" title="Are You Sure?" ok-title="Delete" @ok="deleteSite" ok-variant="danger">
      Are you sure you want to delete this work site? This cannot be undone. Deleting a work site also deletes
      all work orders associated with this site.
    </b-modal>

    <b-modal id="remove-photo-modal" title="Are You Sure?" @ok="removePhoto" ok-title="Remove" ok-variant="danger" ok-only>
      <p>Deleting this photo from the library cannot be undone</p>
    </b-modal>

    <b-modal id="failureModal" title="Oops!" ok-only>Operation failed, try again later. <br>error: {{error}}</b-modal>
  </div>
  <div v-else-if="noSite">
    <h1>Site Could not be found!</h1>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
  name: "Site",
  data() {
    return {
      site: null,
      file: null,
      noSite: false,
      infoActive: true,
      showEditFields: false,
      photosActive: false,
      pendingServices: [],
      completedServices: [],
      photos: [],
      editPropName: "",
      editPropNumber: "",
      editPwsNum: "",
      editStreetAddress: "",
      editCity: "",
      editSource: "",
      editPropType: "",
      editOperatingPeriod: "",
      editTcFrequency: "",
      editNitrateFrequency: "",
      editTreatmentProcess: "",
      editFmManagerName: "",
      editFmManagerPhone: "",
      editChlorineJugs: "",
      editCl17Reagents: "",
      editKeyBoxCodes: "",
      editRoute: "",
      editNotes: "",
      editTechnicianResponsible: null,
      addedPhoto: null,
      selectedPhoto: null,
      url: "",
      photoDescription: "",
      operatingPeriods: ["Year Round", "Seasonal"],
      tcFrequencies: ["Monthly", "Quarterly"],
      nitrateFrequencies: ["Annually", "Quarterly"],
      propTypes: ["MTGH", "RCMP"],
      waterSources: ["Well", "Spring", "Well and Spring"],
      routes: [{ text: 'Select One', value: null }, {text: "No Route", value: null}],
      technicians: [{ text: 'Select One', value: null }],
      error: "",
    }
  },
  async created() {
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
      this.$router.push({ path: "/"});
    }

    try {
      let response = await axios.get('/api/sites/' + this.$route.params.id);
      this.site = response.data;
      this.noSite = false;
    } catch(error) {
      this.error = error.response.data.message;
      this.noSite = true;
    }

    //get services
    try {
      let response = await axios.get('/api/services/site/' + this.site._id);
      this.pendingServices = response.data.pending;
      this.completedServices = response.data.completed;
    } catch(error) {
      this.error = error.response.data.message;
    }

    //get photos
    await this.getPhotos();

    //get routes for route changing options
    try {
      let response = await axios.get('/api/routes');
      for(let i = 0; i < response.data.length; i++) {
        this.routes.push({text: response.data[i].name, value: response.data[i]});
      }

      //get technicians for technician options
      response = await axios.get('/api/users/technicians');
      for(let i = 0; i < response.data.length; i++) {
        this.technicians.push({text: response.data[i].firstName, value: response.data[i]});
      }
    } catch(error) {
      this.error = error.response.data.message;
    }

    this.populateEditFields();
  },
  computed: {
    keyBox() {
      if (this.site.keyBoxCodes) {
        return true;
      }
      return false;
    },
    phoneNumber() {
      var cleaned = ('' + this.site.fmManagerPhone).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    },
    googleLink() {
      //create google maps link
      //maps.google.com/?q=1200%20Pennsylvania%20Ave%20SE,%20Washington,%20District%20of%20Columbia,%2020003
      let address = this.site.streetAddress + ", " + this.site.city
      let replaced = address.split(' ').join('+');
      return "https://maps.google.com/?q=" + replaced;
    },
    userIsAdmin() {
      if (this.$root.$data.user.role == "Admin") {
        return true;
      }
      return false;
    }
  },
  methods: {
    populateEditFields() {
      this.editPropName = this.site.propName
      this.editPropNumber =this.site.propNumber
      this.editPwsNum = this.site.pwsNum
      this.editStreetAddress = this.site.streetAddress
      this.editCity = this.site.city
      this.editSource = this.site.source
      this.editPropType = this.site.propType
      this.editOperatingPeriod = this.site.operatingPeriod
      this.editTcFrequency = this.site.tcFrequency
      this.editNitrateFrequency = this.site.nitrateFrequency
      this.editTreatmentProcess = this.site.treatmentProcess
      this.editFmManagerName = this.site.fmManagerName
      this.editFmManagerPhone = this.site.fmManagerPhone
      this.editChlorineJugs = this.site.chlorineJugs
      this.editCl17Reagents = this.site.cl17Reagents
      this.editExtKeyBoxCodes = this.site.keyBoxCodes
      this.editTechnicianResponsible = this.site.technicianResponsible
      this.editNotes = this.site.notes

      for(let i = 2; i < this.routes.length; i++) {
        if (this.routes[i].value.name == this.site.route.name) {
          this.editRoute = this.routes[i].value;
        }
      }
    },
    infoNowActive() {
      this.populateEditFields();
      this.infoActive = true;
      this.photosActive = false;
    },
    infoNotActive() {
      this.infoActive = false;
    },
    revealEditFields() {
      this.populateEditFields();
      this.showEditFields = true;
    },
    removeEditFields() {
      this.showEditFields = false;
    },
    workOrdersNowActive() {
      this.infoActive = false;
      this.photosActive = false;
    },
    photosNowActive() {
      this.photosActive = true;
      this.infoActive = false;
    },
    photosNotActive() {
      this.photosActive = false;
      this.infoActive = false;
    },
    async saveSite() {
      this.error = "";

      try {
        let response = await axios.put("/api/sites/" + this.site._id, {
          propName: this.editPropName,
          propNumber: this.editPropNumber,
          pwsNum: this.editPwsNum,
          streetAddress: this.editStreetAddress,
          city: this.editCity,
          source: this.editSource,
          propType: this.editPropType,
          operatingPeriod: this.editOperatingPeriod,
          tcFrequency: this.editTcFrequency,
          nitrateFrequency: this.editNitrateFrequency,
          treatmentProcess: this.editTreatmentProcess,
          fmManagerName: this.editFmManagerName,
          fmManagerPhone: this.editFmManagerPhone,
          chlorineJugs: this.editChlorineJugs,
          cl17Reagents: this.editCl17Reagents,
          keyBoxCodes: this.editKeyBoxCodes,
          route: this.editRoute._id,
          technicianResponsible: this.editTechnicianResponsible._id,
          notes: this.editNotes
        });
        this.site = response.data;
        this.showEditFields = false;
      } catch(error) {
        this.error = error.response.data.message;
        this.$bvModal.show("failureModal");
      }
    },
    fileChanged(event) {
      this.file = event.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },
    async upload(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.error = "";
      try {
        const formData = new FormData();
        formData.append('image', this.file, this.file.name);
        formData.append('description', this.photoDescription);
        formData.append('siteId', this.site._id);
        let response = await axios.post("/api/photos", formData);
        this.file = null;
        this.url = "";
        this.photoDescription = "";
        this.photos.push(response.data);
        this.$bvModal.hide('add-photo-modal')
      } catch (error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
    clearFields() {
      this.file = null;
      this.url = "";
      this.photoDescription = "";
    },
    async getPhotos() {
      try {
        let response = await axios.get('/api/photos/' + this.site._id);
        this.photos = response.data;
      } catch(error) {
        this.error = error.response.data.message;
      }
    },
    removePhotoButton(photo) {
      this.selectedPhoto = photo;
      this.$bvModal.show("remove-photo-modal");
    },
    async removePhoto(bvModalEvt) {
      bvModalEvt.preventDefault();
      try {
        let response = await axios.delete("/api/photos/" + this.selectedPhoto._id);
        if (response.status == 200) {
          this.selectedPhoto = null;
          this.$bvModal.hide('remove-photo-modal')
          await this.getPhotos();
        }
      } catch(error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
    serviceDateToString(serviceDate) {
      return moment(serviceDate).format("M/D/YY");
    },
    async deleteSite() {
      try {
        let response = await axios.delete("/api/services/site/" + this.site._id);
        response = await axios.delete("/api/sites/" + this.site._id);
        if (response.status == 200) {
          this.$router.push({ path: "/"});
        }
      } catch(error) {
        this.error = "Error: " + error.response.data.message;
      }
    },
  }
}
</script>

<style scoped>

.site {
  display: flex;
  justify-content: center;
}

.link-text {
  padding-bottom: 10px !important;
}

.link-text {
  margin-bottom: 12px;
}

.collapse {
  padding-right: 0px;
}

.card-body {
  padding-right: 5px;
  padding-left: 5px;
}

.edit-icon {
  justify-content: flex-end;
}

.spacer {
  visibility: hidden;
}

.top-item {
  display: flex;
  flex: auto;
  justify-content: center;
}

.edit-row-container {
  display: flex;
  justify-content: flex-start;
}

.input-title {
  min-width: 115px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #39c0ed !important;
}

.active-tab {
  background-color: #39c0ed;
}

.tab-responsive {
  display: flex;
  justify-content: space-between;
}

.site-info-button-small {
  width: 100%;
}

.header-button-large {
  width: 100%;
}

#file-image {
  margin-bottom: 10px;
}

#textarea {
  margin-bottom: 10px;
}

.photo-card {
  display: flex;
  justify-content: space-between;
}

.spacer {
  visibility: hidden;
}

.remove-photo-button {
  margin-left: 20px;
}

.bg-primary {
  background-color: #213571 !important;
}

.pending {
  margin-bottom: 5px;
}

p {
  margin: 0;
}

.btn-info {
  background-color: #00b8e6 !important;
}

#add-photo-modal .btn-info {
  background-color: #00b8e6 !important;
}

</style>
